.variant-addRow {
  background-color: #fafafa;
  cursor: copy;
  transition: 0.2s all;
}
.variant-addRow:hover {
  background-color: #eee;
}

.variant-noAccess {
  background-color: #fafafa;
  cursor: not-allowed;
  transition: 0.2s all;
}

.variant-removeRow {
  display: flex;
  justify-content: center;
  border: 0;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: transparent;
  transition: 0.2s all;
}

.variant-removeRow:focus {
  background-color: #FFA6A6;
}

.variant-sizeFilled {
  cursor: pointer;
  padding: 10px;
  border-radius: 3px;
  transition: 0.2s all;
  border: 0;
  background-color: transparent;
}

.variant-sizeFilled:hover {
  background-color: #eee;
}

.variant-sizeFilled:focus {
  background-color: #eee;
}

.variant-sizeButton {
  border: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  background-color: #4caf50;
  color: white;
  font-weight: 700;
  border-radius: 50%;
  transition: 0.2s all
}

.variant-sizeButton:hover {
  box-shadow: 1px 3px 5px #9e9e9e;
}

.variant-sizeButton:focus {
  box-shadow: 1px 3px 5px #999999;
}

.variant-listItem {
  transition: 0.2s all;
}

.variant-listItem:hover {
  background-color: #eee;
}
