@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  to {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

.rotating {
  -webkit-animation: rotating 1.5s linear infinite;
  -moz-animation: rotating 1.5s linear infinite;
  -ms-animation: rotating 1.5s linear infinite;
  -o-animation: rotating 1.5s linear infinite;
  animation: rotating 1.5s linear infinite;

  &.fast {
    -webkit-animation: rotating 1s linear infinite;
    -moz-animation: rotating 1s linear infinite;
    -ms-animation: rotating 1s linear infinite;
    -o-animation: rotating 1s linear infinite;
    animation: rotating 1s linear infinite;
  }

  &.slow {
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
  }
}

.new-brand-form-rating {
  &__wrapper {
    display: flex;
  }

  &__header {
    width: 250px;
    padding: 0 56px 0 24px;
    line-height: 41px;

    color: rgba(0, 0, 0, 0.87);
    font-size: 0.8125rem;
    font-weight: 400;
  }

  &__input-wrapper {
    flex: 1;
    display: flex;
    padding: 0 5px;

    > p {
      margin: 0;
      line-height: 41px;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
    }

    .rdt {
      width: 50px;
      margin-left: 15px;
      z-index: 999999 !important;
    }

    > div:last-child {
      width: 150px;
      margin-left: 15px !important;
    }
  }
}


.translatable-language-picker {
  max-width: 35px;
}

.hidden-arrows {
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

body .pa-0 {
  padding: 0 !important;
}
