.dateTimeFilterStyles,
.dateTimeFilterStyles > div.react-datepicker-wrapper,
.dateTimeFilterStyles
  > div
  > div.react-datepicker__input-container
  .dateTimeFilterStyles
  > div
  > div.react-datepicker__input-container
  input {
  width: 100%;
}

.dateTimeFilterStyles .rdt {
  width: 100%;
}
